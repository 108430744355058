<template>
  <div id="app">
    <header-vue />
    <router-view/>
    <footer-vue />
  </div>
</template>

<script>
import FooterVue from '@/layout/Footer.vue'
import HeaderVue from '@/layout/Header.vue'
export default {
  name: 'Index',
  components: {
    FooterVue,
    HeaderVue
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Inter', 'Arial', "microsoft yahei", 'Myriad Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
