<template>
  <div class="footer flex fdc jcsb">
    <div class="w1200 flex aifs jcsb">
      <div class="foot-left flex aifs">
        <dl>
          <dt>{{ $t('foot.products') }}</dt>
          <dd><a href="javascript:void(0)">{{ $t('foot.sparkplug') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.lgnitioncoil') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.oxygensensor') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.noxsensor') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.rvcmanager') }}</a></dd>
        </dl>
        <dl>
          <dt>{{ $t('foot.aboutus') }}</dt>
          <dd><a href="javascript:void(0)">{{ $t('foot.ourstory') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.history') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.ourbrands') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.honour') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.culture') }}</a></dd>
        </dl>
        <dl>
          <dt>{{ $t('foot.news') }}</dt>
          <dd><a href="javascript:void(0)">{{ $t('foot.Ecents') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.techienews') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.socialnews') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.pressmedia') }}</a></dd>
          <dd><a href="javascript:void(0)">{{ $t('foot.newproductslaunch') }}</a></dd>
        </dl>
      </div>
      <div class="foot-right">
        <img src="../assets/image/footer_logo.png" alt="" class="logo">
        <div class="icons flex aic">
          <img src="../assets/image/footer/ft_1.png" alt="">
          <img src="../assets/image/footer/ft_2.png" alt="">
          <img src="../assets/image/footer/ft_3.png" alt="">
          <img src="../assets/image/footer/ft_4.png" alt="">
        </div>
        <p>{{ $t('foot.tel') }}: +86-731-89902911</p>
        <p>{{ $t('foot.mail') }}: info@techieautoparts.com</p>
      </div>
    </div>
    <div class="copy-right flex-cc">
      <p>Copyright ©2022 TECHIE AUTO PARTS</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer {
  height: 298px;
  background: #555 url('../assets/image/footer_bg.png') repeat;
}

.foot-left {
  dl {
    padding-top: 52px;
    width: 172px;
    dt {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
    dd {
      margin-bottom: 2px;
      font-size: 12px;
      color: #fff;
      a {
        &:hover {
          text-decoration: underline;
        }
        display: inline-block;
        padding: 5px 0;
      }
    }
  }
}

.foot-right {
  padding-top: 40px;
  .logo {
    margin-bottom: 20px;
    width: 246px;
  }
  .icons {
    margin-bottom: 20px;
    padding-left: 6px;
    img {
      cursor: pointer;
      margin-right: 8px;
      width: 38px;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  p {
    margin-bottom: 6px;
    padding-left: 12px;
    font-size: 14px;
    color: #fff;
  }
}

.copy-right {
  height: 36px;
  font-size: 12px;
  color: #fff;
  background: #555;
  .cr-left {
    span {
      margin-right: 20px;
    }
  }
}
</style>