<template>
  <div>
    <div class="banner">
      <swiper :options="options">
        <swiper-slide>
          <img class="slide-img" src="../assets/image/banner/banner3.jpg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img class="slide-img" src="../assets/image/banner/banner1.jpg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img class="slide-img" src="../assets/image/banner/banner2.jpg" alt="" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="products">
      <div>
        <h3>{{ $t('body.ourproducts') }}</h3>
        <div class="goods-list">
          <div class="flex-cc">
            <div class="goods flex-cc-fdc">
              <img src="../assets/image/tag.png" alt="" class="tag" />
              <img src="../assets/image/goods1.png" alt="" class="goods-img" />
              <p>Свеча зажигания</p>
            </div>
            <div class="goods flex-cc-fdc">
              <img src="../assets/image/tag.png" alt="" class="tag" />
              <img src="../assets/image/goods2.png" alt="" class="goods-img" />
              <p>Катушка зажигания</p>
            </div>
            <div class="goods flex-cc-fdc">
              <img src="../assets/image/tag.png" alt="" class="tag" />
              <img src="../assets/image/goods3.png" alt="" class="goods-img" />
              <p>Кабель зажигания</p>
            </div>
          </div>
          <div class="flex-cc">
            <div class="goods flex-cc-fdc">
              <img src="../assets/image/tag.png" alt="" class="tag" />
              <img src="../assets/image/goods4.png" alt="" class="goods-img" />
              <p>Датчик кислорода и датчик Nox</p>
            </div>
            <div class="goods flex-cc-fdc">
              <img src="../assets/image/tag.png" alt="" class="tag" />
              <img src="../assets/image/goods5.png" alt="" class="goods-img" />
              <p>Генератор</p>
            </div>
            <div class="goods flex-cc-fdc">
              <img src="../assets/image/tag.png" alt="" class="tag" />
              <img src="../assets/image/goods6.png" alt="" class="goods-img" />
              <p>Стартер</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-us">
      <div class="wrap mb">
        <h3 class="h3">{{ $t('body.aboutus') }}</h3>
        <div class="p1">
          <img src="../assets/image/footer_logo.png" alt="" />
          <span
            >- ведущий бренд, предлагающий превосходные автозапчасти, которые широко
            используются в мотоциклах, автомобилях, малых двигателях, промышленном
            применении, основные продукты включают в себя: катушку зажигания (выход 10
            миллионов штук), свечи зажигания (выход 200 миллионов штук), кислородный
            датчик, датчик NOx, Датчик АБС, стартер, генератор и т.д.</span
          >
        </div>
        <a class="btn" href="javascript:void(0)">более ></a>
      </div>
      <div class="wrap flex aifs">
        <div class="p2">
          <span>Продукция</span> <img src="../assets/image/footer_logo.png" alt="" />
          <span
            >была выбрана крупными OEM-клиентами по всему миру, в том числе: GM, FORD,
            HONDA, GEELY, HAVAL,CHERY, DFSK, SUZUKI, YAMAHA, KTM, TVS, LONCIN, LIFAN, BMW,
            PIAGGIO и т. д. Наши клиенты разбросаны по всему миру. по всему миру более чем
            в 100 странах и регионах.</span
          >
        </div>
        <div class="p2">
          <span>IATF16949, ISO9001 и ISO14001 сертифицированный завод с современными технологиями производства и новейшим автоматическим оборудованием, а также наш талантливый персонал,</span> <img src="../assets/image/footer_logo.png" alt="" /> <span>разрабатывает широкий ассортимент продукции мирового класса, от стандартизированных деталей для оригинального оборудования до изделий для известных частных брендов.</span>
        </div>
      </div>
    </div>

    <div class="news-update">
      <h3>{{ $t('body.news') }}</h3>
      <div class="btns flex-cc">
        <a class="main-btn" href="javascript:void(0)">{{ $t('body.companynews') }} ></a>
        <a class="o-btn" href="javascript:void(0)">{{ $t('body.industrynews') }} ></a>
      </div>
      <div class="wrap flex jcsb">
        <div class="news-left flex aifs">
          <img src="../assets/image/b2.jpg" alt="" />
          <div class="info">
            <h4>КОННОРА ДОЙЛА</h4>
            <p class="p2">
              Мы составили полезное руководство, чтобы вы всегда знали, находится ли ваша катушка зажигания в идеальном состоянии или нет.
            </p>
            <a href="javascript:void(0)">ОПУБЛИКОВАНО 14 СЕНТЯБРЯ 2021 Г.></a>
          </div>
        </div>
        <div class="news-right">
          <div class="news-item">
            <p>
              Проблемы с нашими автомобилями могут привести к серьезным нарушениям в нашей повседневной жизни. Легко воспринимать наши машины как должное, когда они работают нормально, но когда возникает проблема, это может стать неудобством. прочь и выбросить вещи из порядка.
            </p>
          </div>
          <div class="news-item">
            <!-- <h4 class="ellipsis">Optimize cost and improve qualityOptimize</h4> -->
            <p>
              Одной из частых проблем автовладельцев является неисправная катушка зажигания, но не все знают, как ее проверить. Поддерживая контроль над второстепенными деталями в наших автомобилях, мы можем предотвратить несвоевременную проблему. Это означает меньше сбоев, которые, будем честными, всегда случаются в самый неподходящий момент.
            </p>
          </div>
          <div class="news-item">
            <!-- <h4 class="ellipsis">Optimize cost and improve qualityOptimize</h4> -->
            <p>
              Обратите внимание: если у вас есть какие-либо сомнения относительно процесса, обратитесь к профессионалу.
            </p>
          </div>
          <div class="news-item">
            <!-- <h4 class="ellipsis">Optimize cost and improve qualityOptimize</h4> -->
            <p>
              Мы составили полезное руководство, чтобы вы всегда знали, находится ли ваша катушка зажигания в идеальном состоянии или нет. Давайте посмотрим, как проверить катушку зажигания в домашних условиях.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="shares flex fdc">
      <a class="fb" href="javascript:void(0)"></a>
      <a class="tt" href="javascript:void(0)"></a>
      <a class="pi" href="javascript:void(0)"></a>
      <a class="in" href="javascript:void(0)"></a>
      <a class="back" href="javascript:void(0)"></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 3300,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.h100 {
  height: 100%;
}

.swiper-container {
  height: 800px;
}
.swiper-wrapper {
  height: 100%;
}
.swiper-slide {
  height: 800px;
  width: 100vw;
}
.slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
::v-deep {
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    margin: 0 8px !important;
  }
}

.products {
  height: 937px;
  background: url('../assets/image/bg1.png') no-repeat;
  background-size: contain;
  h3 {
    padding: 64px 0 80px;
    font-size: 36px;
    text-align: center;
    color: #fff;
  }
  .goods-list {
    .tag {
      position: absolute;
      top: 0;
      left: 0;
      width: 68px;
      z-index: 11;
    }
    .goods-img {
      height: 180px;
    }
  }
  .goods {
    position: relative;
    margin: 0 15px 30px;
    width: 390px;
    height: 300px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 4px 12px 24px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-20px);
      box-shadow: 4px 12px 24px 0px rgba(0, 0, 0, 0.16);
    }
  }
}

.about-us {
  height: 920px;
  background-color: #00202c;
  background-image: url('../assets/image/bg2.png'), url('../assets/image/bg3.png') ;
  background-position: center top, center bottom;
  background-repeat: no-repeat, no-repeat;
  .wrap {
    margin: 0 auto;
    width: 1440px;
  }
  .mb {
    margin-bottom: 200px;
  }
  .h3 {
    padding: 123px 0 24px;
    font-size: 36px;
    color: #fff;
  }
  .p1 {
    width: 840px;
    font-size: 16px;
    color: #fff;
    line-height: 28px;
    img {
      margin-right: 6px;
      height: 18px;
    }
  }
  .btn {
    display: inline-block;
    margin-top: 24px;
    width: 104px;
    height: 40px;
    background: #00A3DD;
    color: #fff;
    text-align: center;
    line-height: 40px;
    transition: .2s;
    &:hover {
      opacity: 0.8;
    }
  }
  .p2 {
    width: 600px;
    font-size: 16px;
    color: #fff;
    line-height: 28px;
    &:first-child {
      margin-right: 120px;
    }
    img {
      margin-right: 6px;
      height: 18px;
    }
  }
}

.news-update {
  height: 920px;
  h3 {
    padding-top: 120px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 36px;
    color: #000;
  }
  .sub-title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 12px;
    color: #999;
  }
  .btns {
    margin-bottom: 70px;
    a {
      margin: 0 8px;
      display: inline-block;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 12px;
      transition: .2s;
    }
    .main-btn {
      border: 1px solid #00a3dd;
      background: #00a3dd;
      color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
    .o-btn {
      border: 1px solid #666;
      background: #fff;
      color: #666;
      &:hover {
        background: #f3f3f3;
      }
    }
  }
  .wrap {
    margin: 0 auto;
    width: 1440px;
  }
  .news-left {
    width: 840px;
    height: 400px;
    background: #f5f2f5;
    img {
      display: block;
      height: 100%;
      width: 460px;
      object-fit: cover;
    }
    .info {
      padding: 68px 40px;
    }
    h4 {
      margin-bottom: 26px;
      font-size: 20px;
    }
    .p1 {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
    }
    .p2 {
      margin-bottom: 30px;
      line-height: 22px;
      font-size: 14px;
      color: #666;
    }
    a {
      font-size: 14px;
      color: #666;
      text-decoration: underline;
      transition: .2s;
      &:hover {
        color: #00a3dd;
      }
    }
  }
  .news-right {
    flex: 1;
    padding-left: 62px;
    padding-top: 26px;
    width: 0;
    .news-item {
      margin-bottom: 30px;
      h4 {
        margin-bottom: 8px;
        font-size: 16px;
        cursor: pointer;
        transition: .2s;
        &:hover {
          color: #00a3dd;;
        }
      }
      p {
        font-size: 14px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

.shares {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  a {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #d6d9dc;
    border-radius: 50%;
    margin-bottom: 10px;
    &:after {
      content: "";
      display: block;
      width: 35px;
      height: 35px;
      margin: 7px 0 0 7px;
    }
    &:hover {
      background: #cad1d8;
    }
    &.fb {
      &:after {
        background: url("../assets/image/share/icons.png") no-repeat -42px -118px;
      }
      &:hover {
        &:after {
          background-position: 8px -118px;
        }
      }
    }
    &.tt {
      &:after {
        background: url("../assets/image/share/icons.png") no-repeat -36px -40px;
      }
      &:hover {
        &:after {
          background-position: 5px -40px;
        }
      }
    }
    &.pi {
      &:after {
        background: url("../assets/image/share/icons.png") no-repeat -36px 0;
      }
      &:hover {
        &:after {
          background-position: 0 0;
        }
      }
    }
    &.in {
      &:after {
        background: url("../assets/image/share/icons.png") no-repeat -38px -93px;
      }
      &:hover {
        &:after {
          background-position: 3px -93px;
        }
      }
    }
    &.back {
      &:after {
        margin-top: 12px;
        background: url("../assets/image/share/icons.png") no-repeat -36px -145px;
      }
      &:hover {
        background: #014da2;
        &:after {
          background-position: 0 -145px;
        }
      }
    }
  }
}
</style>