
export default {
  nav: {
    products: 'Products',
    aboutus: 'About Us',
    news: 'News',
    contactus: 'Contact Us'
  },
  body: {
    ourproducts: 'OUR PRODUCTS',
    aboutus: 'About Us',
    news: 'News',
    companynews: 'Company News',
    industrynews: 'Industry News',
  },
  foot: {
    products: 'Products',
    sparkplug: 'Spark Plug',
    lgnitioncoil: 'Lgnition coil',
    oxygensensor: 'Oxygen Sensor',
    noxsensor: 'Nox Sensor',
    rvcmanager: 'RVC Manager',

    aboutus: 'About  US',
    ourstory: 'Our Story',
    history: 'History',
    ourbrands: 'Our Brands',
    honour: 'Honour',
    culture: 'Culture',
    
    news: 'News',
    Ecents: 'Ecents',
    techienews: 'Techie News',
    socialnews: 'Social News',
    pressmedia: 'Press / Media',
    newproductslaunch: 'New Products Launch',

    tel: 'Tel',
    mail: 'Mail',
  },
}
