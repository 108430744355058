
export default {
  nav: {
    products: 'Товары',
    aboutus: 'О нас',
    news: 'Новости',
    contactus: 'Свяжитесь с нами'
  },
  body: {
    ourproducts: 'Наши продукты',
    aboutus: 'О нас',
    news: 'Новости',
    companynews: 'Новости компании',
    industrynews: 'Новости отрасли',
  },
  foot: {
    products: 'Товары',
    sparkplug: 'Свеча зажигания',
    lgnitioncoil: 'Катушка зажигания',
    oxygensensor: 'Датчик кислорода',
    noxsensor: 'Датчик NOx',
    rvcmanager: '',
    
    aboutus: 'О нас',
    ourstory: 'Наша история',
    history: 'История',
    ourbrands: 'Наши бренды',
    honour: 'Награды',
    culture: 'Культура',

    news: 'Новости',
    Ecents: 'СОБЫТИЯ',
    techienews: 'ТЕХНИЧЕСКИЕ НОВОСТИ',
    socialnews: 'СОЦИАЛЬНЫЕ НОВОСТИ',
    pressmedia: 'ПЕЧАТНЫЕ СМИ',
    newproductslaunch: 'ЗАПУСК НОВЫХ ПРОДУКТОВ',

    tel: 'ТЕЛ',
    mail: 'ПОЧТА',
  },
}
