<template>
  <div class="header">
    <div class="h100 flex-csb">
      <img src="../assets/image/logo.png" alt="" class="logo">
      <div class="header-right flex aic">
        <ul class="navs flex aic">
          <li class="nav-item">
            <a href="javascript:void(0)">{{ $t('nav.products') }}</a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)">{{ $t('nav.aboutus') }}</a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)">{{ $t('nav.news') }}</a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)">{{ $t('nav.contactus') }}</a>
          </li>
        </ul>
        <div class="search-lang flex aic jcsb">
          <img class="search" src="../assets/image/header/search.png" alt="">
          <div class="lang-box flex-cc">
            <div class="lang" @click.stop="toggleLang" @mouseenter="showLang">{{ lang }}</div>
            <i class="jt"></i>
            <div v-show="langShow" class="lang-pop" @mouseleave="closeLang">
              <p @click="changeLang('en')">English</p>
              <p @click="changeLang('ru')">Россия</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langShow: false,
      lang: 'Россия'
    }
  },
  mounted() {
    document.addEventListener('click', () => {
      this.langShow = false
    })
  },  
  methods: {
    showLang() {
      this.langShow = true
    },
    closeLang() {
      this.langShow = false
    },
    toggleLang() {
      this.langShow = !this.langShow
    },
    changeLang(lang) {
      this.$i18n.locale = lang
      this.lang = lang === 'en' ? 'English' : 'Россия'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  padding: 0 38px;
  z-index: 99;
  height: 80px;
  background: #fff;
  .logo {
    height: 60px;
  }
  .h100 {
    height: 100%;
  }
}

.header-right {
  ul {
    list-style: none;
  }
  .nav-item {
    height: 80px;
    line-height: 80px;
    font-size: 16px;
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      background: url('../assets/image/header/divide.png') no-repeat;
      background-size: cover;
      display: inline-block;
      vertical-align: -1px;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    a {
      padding: 0 24px;
      display: inline-block;
      line-height: 80px;
      transition: .3s;
      &:hover {
        color: #0197DB;
      }
    }
  }
}

.search-lang {
  .search {
    height: 28px;
    padding: 6px;
    margin-left: 12px;
    margin-right: 20px;
  }
  .lang-box {
    position: relative;
    width: 96px;
    height: 28px;
    background: rgba(0,0,0,.1);
    border-radius: 14px;
    cursor: pointer;
  }
  .lang {
    font-size: 12px;
    color: #333;
  }
  .jt {
    margin-left: 4px;
    margin-top: 1px;
    border: 4px solid transparent;
    border-top: 6px solid #333;
    border-bottom: 0;
  }

  .lang-pop {
    position: absolute;
    top: 36px;
    right: 0;
    padding: 10px 20px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ddd;
    p {
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        color: #00a3dd;
      }
      &:first-child {
        border-bottom: 1px solid #ddd;
      }
    }
  }
}
</style>